import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { addCartApi } from "~/apis/cart/add-cart-api";
import { getOptionByTypeApi } from "~/apis/product/get-option-by-type-api";
import { getProductByTypeApi } from "~/apis/product/get-product-by-id-type";
import "~/assets/css/content-styles.css";
import InitialGuideImage from "~/assets/images/initial_guide.png";
import ShirtGuideImage from "~/assets/images/shirts_info.png";
import SizeGuideImage from "~/assets/images/size_guide.png";
import SizeGuideMobileImage from "~/assets/images/size_guide_mobile.png";
import { DetailStyledInput } from "~/components/atoms/detail/input/DetailStyledInput";
import { DetailOptionImage } from "~/components/atoms/detail/option/DetailOptionImage";
import { DetailGuideImage } from "~/components/atoms/detail/preview/DetailGuideImage";
import { DetailGuideSubText } from "~/components/atoms/detail/preview/DetailGuideSubText";
import { DetailSelectBoxTitle } from "~/components/atoms/detail/select/DetailSelectBoxTitle";
import Div from "~/components/atoms/div";
import { currentCategoryState } from "~/store/current-category-state";
import { fabricSelectState } from "~/store/fabric-select-state";
import { ProductType } from "~/types/product-type";
import { SelectedOptionType } from "~/types/selected-option-type";
import { getCookie } from "~/utils/cookie";
import { productOptions } from "~/utils/product-option";
import "../../../styles/content-styles.css";
import CommonTitleText from "../../atoms/CommonTitleText";
import AdditionalInfoText from "../../atoms/detail/additional/AdditionalInfoText";
import { DetailContainer } from "../../atoms/detail/DetailContainer";
import { DetailContentsContainer } from "../../atoms/detail/DetailContentsContainer";
import { DetailDivider } from "../../atoms/detail/DetailDivider";
import { DetailLeftSide } from "../../atoms/detail/DetailLeftSide";
import { DetailPageTitle } from "../../atoms/detail/DetailPageTitle";
import { DetailShopAddButton } from "../../atoms/detail/DetailShopAddButton";
import DetailFabricSelect from "../../molecules/detail/DetailFabricSelect";
import DetailOptionBox from "../../molecules/detail/DetailOptionBox";
import DetailPreviewBox from "../../molecules/detail/DetailPreviewBox";
import DetailPrice from "../../molecules/detail/DetailPrice";
import DetailSelectBox from "../../molecules/detail/DetailSelectBox";
import DetailOptions from "../../organisms/detail/DetailOptions";
import DetailPreview from "../../organisms/detail/DetailPreview";

const ShirtsDetailOrderPage = () => {
  const navigate = useNavigate();
  const fabricSelect = useRecoilValue(fabricSelectState);
  const isTablet = useMediaQuery({ maxWidth: 768 });
  const [totalPrice, setTotalPrice] = useState(0);
  const setCurrentCategory = useSetRecoilState(currentCategoryState);
  const [product, setProduct] = useState<ProductType>({
    id: 0,
    title: "",
    detail: "",
    price: 0,
    type: "",
  });
  const [option, setOption] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<SelectedOptionType[]>(
    []
  );
  const [additionalSelectedOption, setAdditionalSelectedOption] =
    useState<string>("");

  const [size, setSize] = useState({
    age: 0,
    height: 0,
    chestSize: 0,
    neckSize: "",
    waistSize: 0,
    sleeveLength: "",
    shirtsType: "",
  });

  /**
   * 초기 데이터를 받아오는 부분
   * 상품정보, 디테일, 아이템 가격, 타입등 정보를 들고온다.
   */
  useEffect(() => {
    getProductByTypeApi("SHIRTS")
      .then((res) => {
        setProduct(res.data.data);
        setTotalPrice(res.data.data.price);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * 상품의 옵션정보들을 가져오는 부분
   */
  useEffect(() => {
    getOptionByTypeApi("SHIRTS")
      .then((res) => {
        setOption(res.data.data);
        setSelectedOption(Array(Object.keys(res.data.data).length).fill({}));
        setCurrentCategory("SHIRTS");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /**
   * 옵션이 선택될 때마다, 최종 가격을 업데이트 하는 부분
   */
  useEffect(() => {
    let optionPrice = 0;
    selectedOption.forEach((item) => {
      if (item.price) {
        optionPrice += item.price;
      }
    });

    setTotalPrice(product.price + optionPrice);
  }, [selectedOption]);

  /**
   * 옵션을 선택했을 때 발생하는 이벤트 핸들러
   */
  function handleOptionSelect(index: number, optionId: SelectedOptionType) {
    // 현재 배열 상태 복사
    const newArray = [...selectedOption];

    // 특정 인덱스의 값을 변경
    if (newArray[index].id === optionId.id) {
      newArray[index] = {} as SelectedOptionType;
      console.log(newArray);
    } else {
      newArray[index] = optionId;
    }

    // 변경된 배열 상태를 세팅
    setSelectedOption(newArray);
  }

  function handleInputChange(
    e: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) {
    setSize({
      ...size,
      [key]: e.target.value,
    });
  }

  function handleChange(e: React.ChangeEvent<HTMLSelectElement>, key: string) {
    setSize({
      ...size,
      [key]: e.target.value,
    });
  }

  /**
   *
   */
  function handleAddCart() {
    if (fabricSelect.fabricId === 0) {
      return alert("원단을 선택해주세요.");
    }

    if (!getCookie("access_token")) {
      alert("로그인이 필요한 서비스입니다.");
      return navigate("/login");
    }

    addCartApi(
      product.id,
      selectedOption.map((item) => item.id),
      size,
      fabricSelect,
      [
        {
          title: "이니셜 자수(선택, *요금별도)",
          value: additionalSelectedOption,
          price: additionalSelectedOption.length > 0 ? 3000 : 0,
        },
      ]
    )
      .then((res) => {
        alert("장바구니에 추가되었습니다.");
        navigate("/cart");
      })
      .catch((err) => {
        alert("다시 로그인해주세요.");
        navigate("/login");
      });
  }

  function handleRadioButtonClick(value: string) {
    setAdditionalSelectedOption(value);
  }

  return (
    <DetailContainer>
      <DetailContentsContainer>
        <DetailLeftSide>
          <DetailPageTitle>세밀한 셔츠 주문서</DetailPageTitle>

          {isTablet && (
            <>
              <DetailGuideSubText>
                기존에 선호하시는 기성복 사이즈를 입었을 때를 기준으로
                선택해주세요.
              </DetailGuideSubText>
              <DetailGuideSubText>
                예) 기존 95사이즈를 입었을 때 소매가 짧다면, [짧다]를
                선택해주세요.
              </DetailGuideSubText>
              <DetailGuideImage src={ShirtGuideImage} />
              <DetailGuideImage src={SizeGuideMobileImage} />
            </>
          )}

          <DetailSelectBox
            title="나이"
            options={productOptions.ages}
            onChange={(e) => handleChange(e, "age")}
          />

          <DetailSelectBox
            title="키 (cm)"
            options={productOptions.heights}
            onChange={(e) => handleChange(e, "height")}
          />

          <DetailSelectBox
            title="가슴둘레 (가슴둘레가 잘 맞는 셔츠나 저장 사이즈를 선택해주세요)"
            options={productOptions.chests}
            onChange={(e) => handleChange(e, "chestSize")}
          />

          <DetailSelectBox
            title="목"
            options={productOptions.necks}
            onChange={(e) => handleChange(e, "neckSize")}
          />

          <DetailSelectBox
            title="허리둘레"
            options={productOptions.waists}
            onChange={(e) => handleChange(e, "waistSize")}
          />

          <DetailSelectBox
            title="소매기장"
            options={productOptions.seleeveLengths}
            onChange={(e) => handleChange(e, "sleeveLength")}
          />

          <DetailSelectBox
            title="셔츠 (긴팔, 반팔)"
            options={productOptions.shirts}
            onChange={(e) => handleChange(e, "shirtsType")}
          />

          {option != null &&
            Object.keys(option).map((value, index) => (
              <DetailOptions title={value} key={index}>
                {option[value].map((item: any) => (
                  <DetailOptionBox
                    key={item.id}
                    name={item.name}
                    value={item.id}
                    src={item.image}
                    onClick={() => {
                      handleOptionSelect(index, {
                        id: item.id,
                        image: item.image,
                        price: item.price,
                      });
                    }}
                    isSelected={selectedOption[index].id === item.id}
                  />
                ))}
              </DetailOptions>
            ))}

          <DetailFabricSelect isShirts />

          <AdditionalInfoText />

          <DetailSelectBoxTitle>
            이니셜 자수(선택, *요금별도)
          </DetailSelectBoxTitle>
          <DetailStyledInput
            placeholder="원하는 이니셜 자수를 넣어주세요. (*요금별도)"
            value={additionalSelectedOption}
            onChange={(e) => setAdditionalSelectedOption(e.target.value)}
          />

          <Div height="20rem">
            <DetailOptionImage src={InitialGuideImage} />
          </Div>

          <DetailPrice
            price={
              additionalSelectedOption.length > 0
                ? fabricSelect.price + 3000
                : fabricSelect.price
            }
          />

          <DetailShopAddButton onClick={handleAddCart}>
            장바구니 추가
          </DetailShopAddButton>
        </DetailLeftSide>

        <DetailPreview
          guideImage={ShirtGuideImage}
          guideImage2={SizeGuideImage}
          guideText={`기존에 선호하시는 기성복 사이즈를 입었을 때를 기준으로 선택해주세요.
예) 기존 95사이즈를 입었을 때 소매가 짧다면, [짧다]를 선택해주세요.`}
        >
          {selectedOption.map((item, index) => (
            <DetailPreviewBox key={index} src={item.image} />
          ))}
        </DetailPreview>
      </DetailContentsContainer>

      <DetailDivider />

      <CommonTitleText title="상세설명" />

      <div
        className="ck-content"
        dangerouslySetInnerHTML={{ __html: product.detail }}
      />
    </DetailContainer>
  );
};

export default ShirtsDetailOrderPage;
